<template>
  <div>
    <pictureLayer
      @closeState="changeSon($event)"
      v-if="isPicture"
      :picUrl="picUrl"
    ></pictureLayer>
    <!-- 标题 -->
    <div id="exam-info-box">
      <el-row v-if="!showExamineeInfo" style="width: 100%">
        <el-col :span="6" class="exam-info-badge">
          <el-tooltip
            v-if="examOperationTest.examNameLen > 25"
            class="item"
            effect="dark"
            :content="examOperationTest.examName"
            placement="top-start"
          >
            <span>考试名称：{{ examOperationTest.aliasExamName }}</span>
          </el-tooltip>
          <span v-else>考试名称：{{ examOperationTest.aliasExamName }}</span>
        </el-col>
        <el-col :span="6" class="exam-info-badge">
          科目：
          <span v-if="examOperationTest.subjectType == 0">未知</span>
          <span v-else-if="examOperationTest.subjectType == 1">物理</span>
          <span v-else-if="examOperationTest.subjectType == 2">生物</span>
          <span v-else-if="examOperationTest.subjectType == 3">化学</span>
        </el-col>
        <el-col :span="6" class="exam-info-badge"
          >考试题目：{{ examOperationTest.operationTestName }}</el-col
        >
        <el-col :span="6" class="exam-info-badge">
          视频状态：
          <span v-if="!errorState">正常</span>
          <span v-else style="color: red">异常</span>
        </el-col>
      </el-row>
      <el-row v-else style="width: 100%">
        <el-col :span="4" class="exam-info-badge"
          >考生姓名：{{ examineeInfo.studentName	 }}</el-col
        >
        <el-col :span="4" class="exam-info-badge"
          >考号：{{ examineeInfo.examineeCode }}</el-col
        >
        <el-col :span="8" class="exam-info-badge"
          >考试题目：{{ examineeInfo.sysOrgOperationTestName }}</el-col
        >
        <el-tooltip
            class="item"
            effect="dark"
            :content="examineeInfo.examSessionInfo"
            placement="top-start"
          >
          <el-col :span="4" class="exam-info-badge"
            >批次：{{ examineeInfo.examSessionInfo }}</el-col
          >
        </el-tooltip>
        <el-col :span="4" class="exam-info-badge"
          >座位号：{{ examineeInfo.seatNum }}</el-col
        >
      </el-row>
    </div>
    <!-- 视频 -->
    <el-row :gutter="0" class="videoBox">
      <div
        class="videoLoadingBox1"
        v-loading="videoLoading"
        element-loading-text="视频加载中请稍等"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0)"
      ></div>
      <template v-if="!videoLoading && videoLoadingBigBox">
        <div class="videoLoadingBox">
          <div>
            <svg
              t="1689586808729"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="697"
              width="200"
              height="200"
            >
              <path
                d="M381.7 444.3l-25.4-43.9c-57.6 22-109.3 55.9-152.2 98.6l34.1 36.6c0.2-0.2 0.5-0.5 0.7-0.8 35.5-35.5 76.8-63.3 122.7-82.7 6.8-2.8 13.4-5.4 20.1-7.8zM248.4 213.4C177.4 245 113 288.9 58.1 342.5L92.2 379c26-25.4 54.3-48.6 84.3-68.8 30.6-20.7 63.2-38.5 97-53.2l-25.1-43.6zM466.1 590.5c-45.2 9.5-85.3 32.7-115.8 65.1l34.2 36.6c1.9-2.1 3.8-4.1 5.8-6.1 27.9-27.9 63.8-45.1 102.5-49.3l-26.7-46.3zM512 157.7c-39.8 0-78.7 3.6-116.5 10.4l26.7 46.2c29.5-4.4 59.5-6.6 89.8-6.6 81 0 159.6 15.9 233.5 47.1 35.5 15 69.8 33.6 101.9 55.3 30 20.3 58.4 43.4 84.3 68.8l34.1-36.6C848.7 228.2 688.6 157.7 512 157.7z"
                fill="#fff"
                p-id="698"
              ></path>
              <path
                d="M512 816.3m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z"
                fill="#fff"
                p-id="699"
              ></path>
              <path
                d="M542.6 422.9c41.3 3.2 81.5 13 119.7 29.1 46 19.4 87.2 47.3 122.7 82.7 0.2 0.2 0.5 0.5 0.7 0.8l34.1-36.6C741.2 420.6 632.8 372 513 371.7l29.6 51.2zM675.338 852.892l-430-744.76 43.3-25 430 744.76z"
                fill="#fff"
                p-id="700"
              ></path>
            </svg>
            <div
              style="
                margin-top: -30px;
                font-size: 20px;
                color: #fff;
                text-align: center;
                margin-bottom: 20px;
              "
            >
              加载失败!
            </div>
            <div style="display: flex; justify-content: space-around">
              <el-button
                style="width: 90px"
                size="medium"
                @click="videoLoadingBigBox = false"
                >返 回</el-button
              >
              <el-button
                style="width: 90px"
                size="medium"
                type="primary"
                @click="
                  () => {
                    this.PlayerLoadingFunc();
                    this.$router.go(0);
                  }
                "
                >重新加载</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <!-- 插槽 -->
      <slot></slot>
      <div>
        <el-col :span="12" style="height: 532px">
          <div id="mainVideo"></div>
        </el-col>
        <el-col :span="12" style="height: 532px">
          <div id="subVideo"></div>
        </el-col>
      </div>
    </el-row>
    <!--  -->
    <div id="marking-hand-box">
      <el-button
        v-throttle
        v-if="!showExamineeInfo"
        style="margin-right: 50px"
        size="medium"
        type="primary"
        @click="errorReport"
        >异常上报</el-button
      >
      <img src="@/assets/marking/rewind.png" alt @click="customRewind" />
      <img
        v-if="!videoPlay"
        src="@/assets/marking/play.png"
        alt
        @click="customPlay"
      />
      <img
        v-if="videoPlay"
        src="@/assets/marking/pause.png"
        alt
        @click="customPause"
      />
      <img src="@/assets/marking/speed.png" alt @click="customSpeed" />
      <div
        v-if="!openAI"
        class="marking-hand-box-progress-bar"
        ref="markingHandBoxProgressBar"
        @click="switchProgress($event)"
      >
        <div
          class="marking-hand-box-progress-bar-point"
          :style="{
            left: Math.floor(progressWidth * videoProgress - 4) + 'px',
          }"
          @click.stop
        ></div>
        <div v-for="(frame, index) of frameList" :key="index">
          <!--              <el-tooltip class="item" effect="dark" :content="frame.displayContent" placement="top-start">-->
          <div
            class="marking-hand-box-progress-bar-frame-point"
            :style="{
              left:
                Math.floor(
                  progressWidth * calculateVideoProgress(frame.frameTime) - 4
                ) + 'px',
            }"
            @click.stop="jumpPointFrameVideo(frame)"
          ></div>
          <!--              </el-tooltip>-->
        </div>
        <div
          class="marking-hand-box-progress-bar-point-right"
          :style="{
            width: Math.floor(progressWidth * (1 - videoProgress)) + 'px',
          }"
        ></div>
      </div>
      <div
        v-if="openAI"
        class="marking-hand-box-progress-bar-ai"
        ref="markingHandBoxProgressBar"
        @click="switchProgress($event)"
      >
        <div
          v-if="false"
          class="marking-hand-box-progress-bar-point-ai"
          :style="{
            left: Math.floor(progressWidth * videoProgress - 6) + 'px',
          }"
          @click.stop
        ></div>
        <div v-for="(frame, index) of frameList" :key="index">
          <el-tooltip
            ref="pointTooltip"
            class="item"
            effect="dark"
            :content="frame.displayContent"
            placement="top-start"
            :value="firstPointShow && index === 0"
          >
            <div
              class="marking-hand-box-progress-bar-frame-point"
              :style="{
                left:
                  Math.floor(
                    progressWidth * calculateVideoProgress(frame.frameTime) - 4
                  ) + 'px',
              }"
              @click.stop="jumpPointFrameVideo(frame)"
            ></div>
          </el-tooltip>
        </div>
        <div
          class="marking-hand-box-progress-bar-point-right-ai"
          :style="{
            width: Math.floor(progressWidth * (1 - videoProgress)) + 'px',
          }"
        ></div>
        <div
          class="marking-hand-box-progress-bar-frame-point-line-box-ai"
          :style="{ width: Math.floor(progressWidth) + 'px' }"
        ></div>
        <div
          v-for="(operation, index) of examOperationTest.operationQuestions"
          style="z-index: 2"
          :key="operation.operationQuestionId"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="operation.aiOperationItemName"
            placement="bottom-start"
          >
            <div
              class="marking-hand-box-progress-bar-frame-point-line-ai"
              :style="
                index < examOperationTest.operationQuestions.length - 1
                  ? {
                      left:
                        Math.floor(
                          progressWidth *
                            calculateVideoProgress(operation.frameStartTime) -
                            4
                        ) + 'px',
                      width:
                        (Math.floor(
                          progressWidth *
                            calculateVideoProgress(
                              operation.frameEndTime - operation.frameStartTime
                            ) -
                            12
                        ) >= 0
                          ? Math.floor(
                              progressWidth *
                                calculateVideoProgress(
                                  operation.frameEndTime -
                                    operation.frameStartTime
                                ) -
                                12
                            )
                          : 0) + 'px',
                      'z-index': 2,
                    }
                  : {
                      left:
                        Math.floor(
                          progressWidth *
                            calculateVideoProgress(operation.frameStartTime) -
                            4
                        ) + 'px',
                      width:
                        (Math.floor(
                          progressWidth *
                            calculateVideoProgress(
                              totalDuration * 1000 - operation.frameStartTime
                            ) -
                            12
                        ) >= 0
                          ? Math.floor(
                              progressWidth *
                                calculateVideoProgress(
                                  totalDuration * 1000 -
                                    operation.frameStartTime
                                ) -
                                12
                            )
                          : 0) + 'px',
                      'z-index': 2,
                    }
              "
              @click.stop="jumpPointFrameVideo(operation.frameFirst)"
            >
              {{ operation.aiOperationItemName }}
            </div>
          </el-tooltip>
        </div>
        <div
          class="marking-hand-box-progress-bar-frame-point-right-ai"
          :style="{
            width: Math.floor(progressWidth * (1 - videoProgress)) + 'px',
          }"
        ></div>
      </div>
      <div class="marking-hand-box-progress-time">
        {{ currentDurationTimeString }} / {{ totalDurationTimeString }}
      </div>
      <el-select
        v-model="speedValue"
        style="margin-right: 10px; width: 95px"
        @change="customSpeedChange"
      >
        <el-option label="0.5倍速" :value="0.5"></el-option>
        <el-option label="1倍速" :value="1"></el-option>
        <el-option label="2倍速" :value="2"></el-option>
        <el-option label="4倍速" :value="4"></el-option>
        <el-option label="6倍速" :value="6"></el-option>
        <el-option label="8倍速" :value="8"></el-option>
      </el-select>

      <!-- <el-dropdown trigger="hover" placement="bottom" @command="showBigView">
        <el-button type="primary" size="medium"> 考试数据 </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="0">显微镜截图</el-dropdown-item>
          <el-dropdown-item :command="1">试卷拍照</el-dropdown-item>
          <el-dropdown-item :command="2">电子试卷</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <el-button
        v-throttle
        :disabled="!examOperationTest.reportAddr || !examOperationTest.reportAddr.length"
        size="medium"
        type="primary"
        @click="showBigView(2)"
        >电子试卷</el-button
      >
      <el-button
        v-throttle
        :disabled="!examOperationTest.answerHtmlImgAddr || !examOperationTest.answerHtmlImgAddr.length"
        size="medium"
        type="primary"
        @click="showBigView(1)"
        >试卷拍照</el-button
      >
      <el-button
        v-throttle
        :disabled="!examOperationTest.microscopePictureAddr || !examOperationTest.microscopePictureAddr.length"
        size="medium"
        type="primary"
        @click="showBigView(0)"
        >显微镜截图</el-button
      >
      <!-- <el-button v-throttle 
        v-if="
          examOperationTest.examModel == 2 || examOperationTest.examModel == 3
        "
        size="medium"
        type="primary"
        @click="experReportShot"
        >实验报告截图</el-button
      >-->
      <!-- <el-button
        v-throttle
        v-if="!showExamineeInfo"
        style="margin-left: 10px"
        size="medium"
        type="primary"
        @click="errorReport"
        >异常上报</el-button
      > -->
      <!-- 
         v-if="examOperationTest.examModel == 2 || examOperationTest.examModel == 3"
       -->
      <!-- <el-button v-throttle size="medium" type="primary" @click="showPicture"
        >实验报告图片</el-button
      > -->
    </div>

    <!-- 查看大图 -->
    <el-row>
      <pictureView
        v-if="showFace"
        :on-close="closeViewer"
        :url-list="faceList"
      ></pictureView>
    </el-row>

    <!-- 异常弹框 -->
    <el-row>
      <el-dialog
        title="异常上报"
        :visible.sync="errorDialog"
        width="40%"
        @close="closed"
      >
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="examineeErrorDes"
          :rows="3"
          maxlength="50"
          show-word-limit
        ></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button v-throttle type="primary" @click="updateExamineeError"
            >保存</el-button
          >
          <el-button v-throttle @click="errorDialog = false">取 消</el-button>
        </div>
        <el-button
          style="margin-bottom: -30px"
          icon="el-icon-data-line"
          type="text"
          @click="
            () => {
              this.errorInnerTextVisible = true;
            }
          "
          >异常原因</el-button
        >
        <el-dialog
          width="36%"
          title="异常原因"
          :visible.sync="errorInnerTextVisible"
          append-to-body
        >
          <div class="errorInnerTextVisible">
            <span
              @click="
                () => {
                  this.examineeErrorDes =
                    '视频黑屏问题：无法查看到学生操作明细，不满足评分要求。';
                  this.errorInnerTextVisible = false;
                }
              "
              >视频黑屏问题：无法查看到学生操作明细，不满足评分要求。</span
            >
            <el-divider></el-divider>
            <span
              @click="
                () => {
                  this.examineeErrorDes =
                    '视频花屏问题：视频内容花屏，导致无法看清学生操作，无法评定分数。';
                  this.errorInnerTextVisible = false;
                }
              "
              >视频花屏问题：视频内容花屏，导致无法看清学生操作，无法评定分数。</span
            >
            <el-divider></el-divider>
            <span
              @click="
                () => {
                  this.examineeErrorDes =
                    '视视频时长不一致：学生的考试视频时长不一致，在对比学生操作时容易出错。';
                  this.errorInnerTextVisible = false;
                }
              "
              >视视频时长不一致：学生的考试视频时长不一致，在对比学生操作时容易出错。</span
            >
          </div>
        </el-dialog>
      </el-dialog>
    </el-row>
  </div>
</template>
<script>
import Player, { Events } from "xgplayer";
import "xgplayer/dist/index.min.css";
import {
  getExamineeVideoApi,
  updateExamineeErrorApi,
} from "@/api/marking/common.js";
import { isEmpty, durationToTimeSting, throttle } from "@/utils/util";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import pictureView from "@/components/pictureView";
export default {
  name: "dualVideoCom",
  components: {
    ElImageViewer,
    pictureView,
  },
  data() {
    return {
      mainPlayer: null,
      subPlayer: null,
      // mainStreamUrl: '',
      // subStreamUrl: '',
      //控制暂停
      videoPlay: true,
      totalDuration: 0,
      currentDuration: 0,
      timeSetInterval: NaN,
      progressWidth: 0,
      speedValue: 1,
      //过渡属性值
      customTransition: "",
      customOpacity: 0.8,
      submitForm: {
        remark: "",
      },
      examId: "",
      operationTestId: "",
      operationQuestionList: [],
      leftOperationQuestionList: [],
      rightOperationQuestionList: [],
      totalScore: 0,
      cellSelectedStyle: "background-color: red;color:#fff;text-align:center;",
      cellNoneStyle: "border:0;text-align:center;",
      //大图展示
      // showFace: false,
      faceList: [],
      //考生视频异常相关
      errorDialog: false,
      errorState: false,
      examineeErrorInfo: {},
      examineeErrorDes: "",
      // 异常内部弹框
      errorInnerTextVisible: false,
      //控制图片弹框
      isPicture: false,
      picUrl: [],
      // 视频加载框
      mainPlayerLoading: false,
      subPlayerLoading: false,
      videoLoading: true,
      videoLoadingBox: null,
      videoLoadingBigBox: true,
      //
      firstPointShow: false,
      //
      frameList: [],
      aiMarkResult: {},
    };
  },
  props: {
    mainStreamUrl: {
      default: null,
    },
    subStreamUrl: {
      default: null,
    },
    examOperationTest: {
      default: {},
    },
    openAI: {
      default: false,
    },
    showFace: {
      type: Boolean,
      default: false,
    },
    showExamineeInfo: {
      type: Boolean,
      default: false,
    },
    examineeInfo: {
      type: Object
    }
  },
  computed: {
    totalDurationTimeString() {
      return durationToTimeSting(this.totalDuration);
    },
    currentDurationTimeString() {
      return durationToTimeSting(this.currentDuration);
    },
    videoProgress() {
      return (this.currentDuration / this.totalDuration).toFixed(2)
        ? (this.currentDuration / this.totalDuration).toFixed(2)
        : 0;
    },
  },
  mounted() {
    this.examId = this.$route.query.examId;
    this.operationTestId = this.$route.query.operationTestId;
    console.log(
      `examId:${this.examId}, operationTestId:${this.operationTestId}`
    );
    this.$nextTick(() => {
      // this.initPlayer(this.mainStreamUrl, this.subStreamUrl)
      this.progressWidth = Math.floor(
        this.$refs.markingHandBoxProgressBar.clientWidth
      );
    });
  },
  beforeDestroy() {
    this.destroyPlayer();
  },
  watch: {
    mainPlayerLoading(val) {
      if (this.subPlayerLoading && val) {
        this.PlayerLoadingFunc();
      }
    },
    subPlayerLoading(val) {
      if (this.mainPlayerLoading && val) {
        this.PlayerLoadingFunc();
      }
    },
  },
  methods: {
    PlayerLoadingFunc() {
      this.videoLoadingBigBox = false;
      this.videoLoading = false;
      clearTimeout(this.videoLoadingBox);
      this.videoLoadingBox = null;
    },
    //
    calculateVideoProgress(duration) {
      if (duration < 0) {
        duration = 0;
      }
      return ((duration / 1000).toFixed(2) / this.totalDuration).toFixed(2)
        ? ((duration / 1000).toFixed(2) / this.totalDuration).toFixed(2)
        : 0;
    },

    //显示图片弹框
    showPicture() {
      if (this.examOperationTest.reportAddr.length <= 0) {
        this.msgWarning("未上传实验报告截图");
        return;
      }
      let imgResult = [];
      this.examOperationTest.reportAddr.forEach((item) => {
        if (!isEmpty(item)) {
          imgResult.push(this.$imageAddress(item));
        }
      });
      this.picUrl = imgResult;
      this.isPicture = true;
    },
    //异常上报弹窗
    errorReport() {
      this.errorDialog = true;
    },
    //获取考生视频数据
    getExamineeVideo(examineeId) {
      getExamineeVideoApi(examineeId).then((res) => {
        if (res.success) {
          this.errorState = false;
          this.examineeErrorInfo = {};
          if (!res.data.length) {
            this.errorState = true;
          }
          res.data.forEach((item) => {
            //0-无异常 1-有异常
            if (item.errorStatus == 1) {
              this.errorState = true;
            }
            this.examineeErrorInfo = item;
          });
        } else {
          if (res.code != 500) {
            this.msgWarning(res.code, res.msg);
          }
        }
      });
    },
    //上报考生视频异常信息
    updateExamineeError() {
      let json = {
        examExamineeAnswerRecordVideoId:
          this.examineeErrorInfo.examExamineeAnswerRecordVideoId,
        examineeId: this.examineeErrorInfo.examineeId,
        errorInfo: this.examineeErrorDes,
        errorStatus: 1,
      };
      const loading = this.globalLoading("正在上报,请稍后...");
      updateExamineeErrorApi(json)
        .then((res) => {
          loading.close();
          if (res.success) {
            this.msgSuccess("上报成功");
            this.errorState = true;
          } else {
            if (res.code != 500) {
              this.msgWarning(res.code, res.msg);
            }
          }
          this.closed();
        })
        .catch(() => {
          loading.close();
        });
    },
    closed() {
      this.errorDialog = false;
      this.examineeErrorDes = "";
    },
    initPlayer(mainStreamUrl, subStreamUrl) {
      console.log("this.mainPlayer", this.mainPlayer);
      this.destroyPlayer();
      this.videoLoadingBigBox = true;
      this.videoLoading = true;
      this.mainPlayerLoading = false;
      this.subPlayerLoading = false;
      this.videoLoadingBox = setTimeout(() => {
        this.videoLoading = false;
        this.destroyPlayer();
      }, 10000);
      this.$nextTick(() => {
        //主视频加载
        this.mainPlayer = new Player({
          id: "mainVideo",
          url: mainStreamUrl,
          playbackRate: 1,
          //初始化显示视频首帧
          videoInit: true,
          //音量(自动播放需配合静音)
          volume: 0,
          autoplayMuted: true,
          //自动播放
          autoplay: true,
          //循环播放
          // loop: false,
          //关闭控制条
          controls: false,
          closeVideoClick: true,
          loop: true,
          width: "100%",
          height: "100%",
        });
        //辅视频加载
        this.subPlayer = new Player({
          id: "subVideo",
          url: subStreamUrl,
          playbackRate: 1,
          //初始化显示视频首帧
          videoInit: true,
          //音量(自动播放需配合静音)
          volume: 0,
          autoplayMuted: true,
          //自动播放
          autoplay: true,
          //循环播放
          // loop: false,
          //关闭控制条
          controls: false,
          closeVideoClick: true,
          loop: true,
          width: "100%",
          height: "100%",
        });
        this.mainPlayer.on("play", () => {
          this.videoPlay = true;
          this.mainPlayerLoading = true;
          this.currentDuration = this.mainPlayer.currentTime
            ? this.mainPlayer.currentTime
            : 0;
          // this.speedValue = 1
          this.customSpeedChange(this.speedValue);
          if (
            this.aiMarkResult.aiResultOperationQuestionItemDTOList &&
            this.aiMarkResult.aiResultOperationQuestionItemDTOList > 0
          ) {
            this.aiMarkResult.aiResultOperationQuestionItemDTOList.forEach(
              (item, index) => {
                let currentFrameList = this.$emit("renderPointFrame", item);
                if (currentFrameList.length > 0) {
                  // for (let i = 0; i < currentFrameList.length; i++) {
                  //     let frame = currentFrameList[i];
                  //     if (frame.frameTime && frame.frameTime > this.totalDuration) {
                  //         frame.frameTime = this.totalDuration
                  //     }
                  // }
                  this.frameList = this.frameList.concat(currentFrameList);
                }
              }
            );
          }
          this.timeSetInterval = setInterval(() => {
            // 进度条定时器
            this.currentDuration = this.mainPlayer.currentTime
              ? this.mainPlayer.currentTime
              : 0;
            // this.currentDuration = this.currentDuration + 1
            if (this.currentDuration >= this.totalDuration) {
              this.mainPlayer.replay();
              this.subPlayer.replay();
            }
          }, 200);
          this.totalDuration = this.mainPlayer.duration
            ? this.mainPlayer.duration
            : 0;
          if (
            this.totalDuration !== 0 &&
            this.totalDuration > this.mainPlayer.duration
          ) {
            this.totalDuration = this.mainPlayer.duration
              ? this.mainPlayer.duration
              : 0;
          }
          this.firstPointShow = true;
        });
        this.subPlayer.on("play", () => {
          this.subPlayerLoading = true;
          this.totalDuration = this.subPlayer.duration
            ? this.subPlayer.duration
            : 0;
          if (
            this.totalDuration !== 0 &&
            this.totalDuration > this.subPlayer.duration
          ) {
            this.totalDuration = this.subPlayer.duration
              ? this.subPlayer.duration
              : 0;
          }
          this.firstPointShow = true;
        });
        this.mainPlayer.on(Events.DESTROY, () => {
          clearInterval(this.timeSetInterval);
        });
        // this.mainPlayer.on(Events.ENDED, () => {})
        // this.mainPlayer.on(Events.PLAYING, () => {})
        // this.subPlayer.on(Events.PLAYING, () => {})
      });
    },
    /*  customMouseenter () {
       this.customTransition = 'all 0.5s'
       this.customOpacity = 0.8
     },
     customMouseleave () {
       this.customTransition = 'all 1s ease-in 0.25s'
       this.customOpacity = 0;
     }, */
    //快退5s
    customRewind() {
      if (this.currentDuration < 5) {
        this.mainPlayer.currentTime = 0;
        this.subPlayer.currentTime = 0;
        return;
      }
      this.mainPlayer.currentTime = this.currentDuration - 5;
      this.subPlayer.currentTime = this.currentDuration - 5;
    },
    //播放
    customPlay() {
      console.log("play");
      this.videoPlay = true;
      this.mainPlayer.play();
      this.subPlayer.play();
    },
    customSwitchProgress() {
      this.mainPlayer.currentTime = this.currentDuration;
      this.subPlayer.currentTime = this.currentDuration;
    },
    //暂停
    customPause() {
      console.log("paused");
      this.videoPlay = false;
      this.mainPlayer.pause();
      this.subPlayer.pause();
    },
    //快进5s
    customSpeed() {
      if (this.currentDuration + 5 > this.totalDuration) {
        return;
      }
      this.mainPlayer.currentTime = this.currentDuration + 5;
      this.subPlayer.currentTime = this.currentDuration + 5;
    },
    //播放倍数改变
    customSpeedChange(num) {
      this.mainPlayer.playbackRate = Number(num);
      this.subPlayer.playbackRate = Number(num);
    },

    jumpPointFrameVideo(aiResultOperationQuestionItemDTO) {
      this.currentDuration = (
        aiResultOperationQuestionItemDTO.frameTime / 1000
      ).toFixed(2);
      this.customSwitchProgress();
    },
    switchProgress(e) {
      this.currentDuration = Math.floor(
        (e.offsetX / this.progressWidth) * this.totalDuration
      );
      this.customSwitchProgress();
    },
    microscopeShot() {
      this.showBigView(0);
    },
    experReportShot() {
      this.showBigView(1);
    },
    showBigView(type) {
      switch (type) {
        case 0:
          this.checkAndPush(
            this.examOperationTest.microscopePictureAddr,
            "未上传显微镜截图"
          );
          break;
        case 1:
          this.checkAndPush(
            this.examOperationTest.answerHtmlImgAddr,
            "未上传试卷拍照"
          );
          break;
        case 2:
          this.checkAndPush(
            this.examOperationTest.reportAddr,
            "未上传电子试卷"
          );
          break;
      }
    },
    checkAndPush(addr, warningMsg) {
      if (addr == null || addr.length <= 0) {
        this.$message.warning(warningMsg);
        return;
      }
      this.faceList = addr.map(this.$imageAddress);
      // this.showFace = true;
      this.$emit("changeShowFace",true)
      this.addPictureFlipBtn();
      // this.handlePictureBoxEventFunc();
    },
    answerHtmlImgBtn() {
      if (!this.examOperationTest.answerHtmlImgAddr.length) {
        return this.$message.warning("无电子试卷");
      }
      this.faceList = this.examOperationTest.answerHtmlImgAddr.map(
        this.$imageAddress
      );
    },
    startPlayer(mainStreamUrl, subStreamUrl) {
      this.mainPlayer.videoInit = true;
      this.subPlayer.videoInit = true;
      this.customSpeedChange(this.speedValue);
      this.videoPlay = true;
    },
    destroyPlayer() {
      // this.mainPlayer.destroy(false)
      // this.subPlayer.destroy(false)
      if (!!this.mainPlayer) {
        this.mainPlayer.destroy();
        this.subPlayer.destroy();
        clearInterval(this.timeSetInterval);
        this.mainPlayer = null;
        this.subPlayer = null;
      }
    },
    closeViewer() {
      this.faceList = [];
      // this.showFace = false;
      this.$emit("changeShowFace",false)
    },
    //接收组件的信息 (销毁组件即关闭图片弹窗)
    changeSon(event) {
      if (event === true) {
        this.shutDownIsPicture();
      }
    },
    // 关闭图片
    shutDownIsPicture() {
      this.isPicture = false;
    },
    // 添加图片翻转按钮,优化按钮样式
    addPictureFlipBtn() {
      this.$nextTick(() => {
        const wrapper = document.querySelector(
          ".el-image-viewer__actions__inner"
        );
        let div = wrapper.querySelector(".flip-picture");
        if (!div) {
          const flipImg = document.createElement("div");
          flipImg.classList.add("flip-picture");
          flipImg.addEventListener("click", () => {
            const img = document.querySelector(
              ".el-image-viewer__canvas .el-image-viewer__img"
            );
            img.style.transform = img.style.transform + "scaleX(-1)";
          });
          wrapper.insertBefore(flipImg, wrapper.children[5]);
        }
        // 优化样式
        const closeImageBtn = document.querySelector(".el-image-viewer__close");
        const prevImageBtn = document.querySelector(".el-image-viewer__prev");
        const nextImageBtn = document.querySelector(".el-image-viewer__next");
        closeImageBtn &&
          (closeImageBtn.style.backgroundColor = "rgb(47,47,47)");
        prevImageBtn && (prevImageBtn.style.backgroundColor = "rgb(47,47,47)");
        nextImageBtn && (nextImageBtn.style.backgroundColor = "rgb(47,47,47)");
      });
    },
    // 给图片预览框去除鼠标滚动事件，添加移动事情
    // handlePictureBoxEventFunc() {
    //   this.$nextTick(() => {
    //     const image = document.querySelector(
    //       ".imageContainer .el-image-viewer__canvas img"
    //     );
    //     window.addEventListener("wheel", (e) => {
    //       // image.style.transform.scale = 1;
    //       // console.log(this.$refs.imageContainer.clientWidth);
    //     });
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
#exam-info-box {
  width: 100%;
  height: 50px;
  line-height: 50px;
  /*  position: absolute;
  top: 0; */
  background-color: #409eff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 200;
}

#marking-hand-box {
  width: 100%;
  height: 55px;
  /*  position: absolute;
  bottom: 0; */
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 201;
}

#marking-hand-box > img {
  margin-right: 10px;
  cursor: pointer;
}

.exam-info-badge {
  font: normal bolder 16px "Source Han Sans CN-Medium, Source Han Sans CN";
  color: #fff;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .el-message-box > .el-message-box__btns > {
  text-align: center;
}

::v-deep .el-input__inner {
  height: 32px;
  text-align: center;
}

.marking-hand-box-progress-bar {
  width: 580px;
  height: 10px;
  line-height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 8px;
  position: relative;
  z-index: 0;
  background: #409eff;
  cursor: pointer;
}
.marking-hand-box-progress-bar-point {
  width: 8px;
  height: 8px;
  border: 2px solid #409eff;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  z-index: 2;
  top: -1px;
  background: #ffffff;
  display: inline-block;
  left: 0;
  cursor: pointer;
  //transition: all 2s ease;
}
.marking-hand-box-progress-bar-point-right {
  position: absolute;
  height: 10px;
  border-radius: 10px;
  z-index: 1;
  background: #ffffff;
  display: inline-block;
  right: 0;
  pointer-events: none;
  //transition: all 2s ease;
}

.marking-hand-box-progress-time {
  width: 150px;
  margin-right: 25px;
}

::v-deep .xgplayer-start {
  display: none !important;
}
::v-deep .xgplayer-error {
  display: none !important;
}
::v-deep video {
  object-fit: cover;
}

// 异常原因例子
.errorInnerTextVisible {
  span {
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}

//
.videoLoadingBox,
.videoLoadingBox1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #00000090;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}
.videoLoadingBox1 {
  background: #00000000;
  z-index: 99;
}

::v-deep .flip-picture {
  height: 30px;
  width: 25px;
  cursor: pointer;
  background: url("~@/assets/flipPicture.png") center no-repeat;
  background-size: 25px 20px;
}

//::v-deep video {
//  object-fit: cover;
//}

.marking-hand-box-progress-bar-ai {
  width: 580px;
  height: 10px;
  line-height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 18px;
  //border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  z-index: 0;
  background: #a9d5fd;
  cursor: pointer;
}
.marking-hand-box-progress-bar {
  width: 580px;
  height: 10px;
  line-height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  //border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  z-index: 0;
  background: #a9d5fd;
  cursor: pointer;
}

.marking-hand-box-progress-bar-frame-point {
  width: 8px;
  height: 8px;
  border: 2px solid #4e67e8;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  z-index: 3;
  top: -1px;
  background: #ffffff;
  display: inline-block;
  left: 0;
  cursor: pointer;
}

.marking-hand-box-progress-bar-frame-point-line-box {
  height: 18px;
  border-bottom-right-radius: 10px;
  line-height: 18px;
  position: absolute;
  background: #a9d5fd;
  border-bottom-left-radius: 8px;
  z-index: 0;
  top: 10px;
  display: inline-block;
  left: 0;
  cursor: pointer;
  overflow: hidden; //超出隐藏
  white-space: nowrap; //不换行，同一行展示
}

.marking-hand-box-progress-bar-frame-point-ai {
  width: 8px;
  height: 8px;
  border: 2px solid #4e67e8;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  z-index: 3;
  top: -1px;
  background: #ffffff;
  display: inline-block;
  left: 0;
  cursor: pointer;
}

.marking-hand-box-progress-bar-frame-point-line-box-ai {
  height: 18px;
  border-bottom-right-radius: 10px;
  line-height: 18px;
  position: absolute;
  background: #a9d5fd;
  border-bottom-left-radius: 8px;
  z-index: 0;
  top: 10px;
  display: inline-block;
  left: 0;
  cursor: pointer;
  overflow: hidden; //超出隐藏
  white-space: nowrap; //不换行，同一行展示
}

.marking-hand-box-progress-bar-frame-point-line-ai {
  height: 18px;
  width: 100%;
  color: #4e67e8;
  //border-left: 0px solid #efefef;
  line-height: 18px;
  position: absolute;
  z-index: 1;
  top: 10px;
  background: rgb(239, 239, 239, 0);
  display: inline-block;
  left: 0;
  cursor: pointer;
  font-size: 14px;
  overflow: hidden; //超出隐藏
  white-space: nowrap; //不换行，同一行展示
}

.marking-hand-box-progress-bar-point-ai {
  width: 8px;
  height: 8px;
  border: 2px solid #1890ff;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  z-index: 2;
  top: -1px;
  background: #ffffff;
  display: inline-block;
  left: 0;
  cursor: pointer;
}

.marking-hand-box-progress-bar-point-right-ai {
  position: absolute;
  height: 10px;
  //border-radius: 10px;
  border-top-right-radius: 8px;
  z-index: 1;
  background: #ffffff;
  display: inline-block;
  right: 0;
  pointer-events: none;
}

.marking-hand-box-progress-bar-frame-point-right-ai {
  position: absolute;
  top: 10px;
  height: 18px;
  //border-radius: 0px;
  border-bottom-right-radius: 8px;
  z-index: 1;
  background: rgb(255, 255, 255);
  display: inline-block;
  right: 0;
  pointer-events: none;
}
</style>
