import { request } from '@/utils/request.js'

//获取考生视频数据
export function getExamineeVideoApi (examineeId) {
  return request({
    url: `/exam/score/video/examinee/list/${examineeId}`,
    method: 'post',
  })
}

//上报考生视频异常信息
export function updateExamineeErrorApi (data) {
  return request({
    url: `/exam/score/video/examinee/update/error`,
    method: 'post',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    data: data
  })
}
